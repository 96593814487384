import { HttpParams } from '@angular/common/http';
import { ESortMethod } from '../components/list-filter/sort/sort.model';

export interface ISearchOptions {
  pageable: IPageableDTO;
  searchText?: string;
}

export interface IPageableDTO {
  page: number;
  size: number;
  sortProperty: string;
  sortMethod: ESortMethod;
}

export function getPageableHttpParams(pageable: IPageableDTO): HttpParams {
  const params = new HttpParams()
    .set('page', pageable.page.toString())
    .set('size', pageable.size.toString())
    .set('sort', `${pageable.sortProperty},${pageable.sortMethod}`);

  return params;
}
